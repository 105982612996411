import React from 'react'
import { Link } from 'gatsby'

// Styles 
import { SuccessHolder } from "./Success.styles"

const Success = () => (
    <SuccessHolder>
        <h4>Tack</h4>
        <p>Din offertförfrågan är skickad</p>
        <Link to={'/'}>Tillbaka till Startsidan</Link>

    </SuccessHolder>
)
export default Success