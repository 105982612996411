import React from "react"
import { graphql } from "gatsby"
// Components
import Layout from "components/Layout"
import Image from "components/Image" 
//import Image from "gatsby-image"
import SEO from "components/SEO"
import Kontaktform from "components/Kontaktform"
import Success from "components/Success"
import AccordionComponent from 'components/Accordion'
import TwoCols from "components/TwoCols"
//import PaketPris from 'components/PaketPris'
import CardsboxKontakt from "components/CardsboxKontakt" 
import { Container, Row, Col } from 'react-bootstrap'


import packett from 'images/packett.jpg'
import packtva from 'images/packtva.jpg'
import packtre from 'images/packtre.jpg'



// Styles
import { TemplateStyles  } from 'styles/TemplateStyles';

const Page = ({ data }) => {
  const page = data.markdownRemark 

  return (
    <Layout className="page-layout trigger-hero"> 
    
      <SEO title={page.frontmatter.seotitle} description={page.frontmatter.seodescription}/>
      <Image className="page-hero-img trigger-hero"  fluid={page.frontmatter.image.childImageSharp.fluid} alt={page.frontmatter.titleh1}/>
      <TemplateStyles>
      <main className={page.frontmatter.class}>
      
        <h1>{page.frontmatter.titleh1}</h1>
        <Image className="page-hero-logo-img trigger-hero" fluid={page.frontmatter.herologo.childImageSharp.fluid} alt={page.frontmatter.titleh1}/>
        <p className="hero-subtitle">{page.frontmatter.subtitle}</p>




        {page.frontmatter.slug !== "transport" && page.frontmatter.slug !== "svar" && page.frontmatter.slug !== "kontakt" && 
          <>
        <div className="subpage-text">
          
            <TwoCols 
              Class="subpage-twocols-holder"
              ContentLeftHeader={page.frontmatter.leftcontentheader}
              ContentLeft={page.frontmatter.leftcontent}
              ContentRightHeader={page.frontmatter.rightcontentheader}
              ContentRight={page.frontmatter.rightcontent}
            />
          </div>
          </>
          }

          {page.frontmatter.slug === "svar" && 
          <>
        <div className="subpage-text">
          
            <TwoCols 
              Class="subpage-twocols-holder swish-svar"
              ContentLeftHeader={page.frontmatter.leftcontentheader}
              ContentLeft={page.frontmatter.leftcontent}
              ContentRightHeader={page.frontmatter.rightcontentheader}
              ContentRight={page.frontmatter.rightcontent}
              ImageRight={page.frontmatter.image2.childImageSharp.fluid}
            />
          </div>

          <Container>
            <AccordionComponent />
          </Container>

          </>
          }

          {page.frontmatter.slug === "bohagsflytt" && 
          <>
            <Container fluid="true">
              {/* <PaketPris 
                pris='2300kr'
                header='PRIVATPAKET – LILLA'
                description='Med lilla flyttpaketet ingår 2 man á 4 timmar med lastbil upp till 22m³. Avbokningsskydd ingår.'
                />
                <PaketPris 
                    pris='3300kr'
                    header='PRIVATPAKET – MELLAN'
                    description='Med mellan flyttpaketet ingår 3 man á 4 timmar med lastbil upp till 22m³. Avbokningsskydd ingår.'
                />
                <PaketPris 
                    pris='4600kr'
                    header='PRIVATPAKET – STORA'
                    description='Med stora flyttpaketet ingår 4 man á 4 timmar med två lastbilar upp till 22m³ vardera. Avbokningsskydd ingår.'
                /> */}

              <Row>
                <Col style={{width: "33%"}}><img src={packett} style={{width: "100%"}} alt="Flyttexpressen" /></Col>
                <Col style={{width: "33%"}}><img src={packtva} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                <Col style={{width: "33%"}}><img src={packtre} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
              </Row>
            </Container>
            </>
            }
            {page.frontmatter.slug === "foretagsflytt" && 
            <>
                <Container fluid="true" >
                  {/* <PaketPris 
                    pris="3800kr"
                    header="FÖRETAGSPAKET – LILLA"
                    description="Med lilla flyttpaketet ingår 2 man á 4 timmar med lastbil upp till 22m³. Avbokningsskydd ingår."
                    />
                  <PaketPris 
                        pris="5700kr"
                        header="FÖRETAGSPAKET – MELLAN"
                        description="Med mellan flyttpaketet ingår 3 man á 4 timmar lastbil upp till 22m³. Avbokningsskydd ingår."
                    />
                  <PaketPris 
                        pris="7600kr"
                        header="FÖRETAGSPAKET – STORA"
                        description="Med stora flyttpaketet ingår 4 man á 4 timmar med två lastbilar upp till 22m³ vardera. Avbokningsskydd ingår."
                    /> */}
                    <Row>
                      <Col style={{width: "33%"}}><img src={packett} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                      <Col style={{width: "33%"}}><img src={packtva} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                      <Col style={{width: "33%"}}><img src={packtre} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                    </Row>
                </Container>
              </>
              }

          
            <div className="subpage-text">
            {page.frontmatter.slug === "bohagsflytt" && 
              <>
                <Container>
                  <div className="div-content" dangerouslySetInnerHTML={{ __html: page.html }} />
                  <Image className="template-content-img" fluid={page.frontmatter.image.childImageSharp.fluid} alt={page.frontmatter.titleh1}/>

                </Container>
                <TwoCols 
                  Class="subpage-twocols-holder rut-boxar"
                  ContentLeftHeader={page.frontmatter.leftcontentheaderbottom}
                  ContentLeft={page.frontmatter.leftcontentbottom}
                  ContentRightHeader={page.frontmatter.rightcontentheaderbottom}
                  ContentRight={page.frontmatter.rightcontentbottom}
                />
              </>
            }

            {page.frontmatter.slug === "foretagsflytt" && 
              <>
                <Container>
                  <div className="div-content" dangerouslySetInnerHTML={{ __html: page.html }} />
                </Container>
              </>
            }

            {page.frontmatter.slug === "tunglyft" && 
              <>
                <Container>
                  <div className="div-content" dangerouslySetInnerHTML={{ __html: page.html }} />
                  <Container fluid="true" >
                  <Row>
                <Col style={{width: "33%"}}><img src={packett} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                <Col style={{width: "33%"}}><img src={packtva} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                <Col style={{width: "33%"}}><img src={packtre} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
              </Row>
                    {/* <PaketPris 
                      pris="1500kr"
                      header="LILLA KASSASKÅPET & VAPENSKÅPET"
                      description="Vi hjälper er inom Göteborg, Borås, Växjö med en vikt upp till 150 kg. Vi finns tillgängliga med kort varsel."
                      />
                    <PaketPris 
                          pris="3000kr"
                          header="MELLAN KASSASKÅPET & VAPENSKÅPET"
                          description="Vi hjälper er inom Göteborg, Borås, Växjö med en vikt upp till 250 kg. Vi finns tillgängliga med kort varsel."
                      />
                    <PaketPris 
                          pris="5000kr"
                          header="STORA KASSASKÅPET & VAPENSKÅPET"
                          description="Vi hjälper er inom Göteborg, Borås, Växjö med en vikt upp till 500 kg. Vi finns tillgängliga med kort varsel."
                      /> */}
                  </Container>

                  <div className="div-content">
                    <h3>{page.frontmatter.leftcontentheaderbottom}</h3>
                    <p>{page.frontmatter.rightcontentbottom}</p>
                  </div>

                </Container>
               
              </>
            }

            {page.frontmatter.slug === "pianoflytt" && 
            <>
                <Container fluid="true" >
                <Row>
                  <Col style={{width: "33%"}}><img src={packett} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                  <Col style={{width: "33%"}}><img src={packtva} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                  <Col style={{width: "33%"}}><img src={packtre} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                </Row>
                  {/* <PaketPris 
                    pris="2500kr"
                    header="PIANOFLYTT"
                    description="
                    Litet Piano 2500kr (Under 120cm)-
                    Stort Piano 3500kr (Över 120cm)-
                    Priserna inkl. moms efter rutavdrag
                    Emballerad och säkert transporterad
                    100kr/mil tillkommer utanför Malmö / Lund
                    Kan utföras med kort varsel"
                    />
                  <PaketPris 
                        pris="3500kr"
                        header="FLYGEL"
                        description="
                        Liten Flygel 3500kr (Under 150cm)-
                        Stort Flygel 5500kr (Över 150cm)-
                        Emballerad och säkert transporterad
                        100kr/mil tillkommer utanför Malmö / Lund
                        Kan utföras med kort varsel"
                    /> */}
                </Container>
                <Container>
                  <Image className="template-content-img" fluid={page.frontmatter.image.childImageSharp.fluid} alt={page.frontmatter.titleh1}/>
                </Container>

                <TwoCols 
                  Class="subpage-twocols-holder rut-boxar"
                  ContentLeftHeader={page.frontmatter.leftcontentheaderbottom}
                  ContentLeft={page.frontmatter.leftcontentbottom}
                  ContentRightHeader={page.frontmatter.rightcontentheaderbottom}
                  ContentRight={page.frontmatter.rightcontentbottom}
                />

              </>
              }

            {page.frontmatter.slug === "transport" && 
              <>
                <Container >
                <div className="div-content">
                  <h3>{page.frontmatter.leftcontentheader}</h3>  
                  <p>{page.frontmatter.leftcontent}</p>  
                </div>
                </Container>

                <Container>
                <div className="div-content">
                  <h3>{page.frontmatter.rightcontentheader}</h3>  
                  <p>{page.frontmatter.rightcontent}</p>  
                </div>
                </Container>

                <Container fluid="true" >

                <Row>
                  <Col style={{width: "33%"}}><img src={packett} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                  <Col style={{width: "33%"}}><img src={packtva} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                  <Col style={{width: "33%"}}><img src={packtre} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                </Row>
                  {/* <PaketPris 
                    pris="EFFEKTIV BÄRHJÄLP"
                    header="Kontakta oss för pris"
                    description="
                    Ni betalar bara för timmarna ni använder oss.
                    Vi tar med emballage etc vid behov
                    Ni får proffshjälp med kort varsel
                    Vi har serviceminded personal
                    Vi ser lösningar inte problem
                    Vi har iD06"
                    />
                  <PaketPris 
                        pris="PRISVÄRT TRANSPORTPAKET"
                        header="Kontakta oss för pris"
                        description="
                        Vi har goda referenser
                        Lätt Lastbil upp till 22m³ med 1-3 Man
                        Lift 750kg med invändig höjd 2.30cm
                        Vi hjälper er med kort varsel
                        Betala endast för de timmar ni behöver hjälp
                        Vi har ID06"
                    /> */}
                </Container>

                
               
             </> 
             }

            {page.frontmatter.slug === "magasinering" && 
              <>
                <Container fluid="true" >
                <Row>
                  <Col style={{width: "33%"}}><img src={packett} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                  <Col style={{width: "33%"}}><img src={packtva} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                  <Col style={{width: "33%"}}><img src={packtre} style={{width: "100%"}} alt="Flyttexpressen"  /></Col>
                </Row>
                  {/* <PaketPris 
                    pris="600 kr /mån"
                    header="MAGASINERING
                    10 KUBIK"
                    description="
                    10 kubikmeter
                    Motsvarar en halvfull lastbil
                    Inga dolda avgifter"
                    />
                  <PaketPris 
                        pris="100 kr /mån"
                        header="FÖRVARING
                        PALLPLATS"
                        description="
                        Egen pallplats
                        Plats för 12 flyttlådor
                        Inga dolda avgifter"
                    /> */}
                </Container>

                <Container>
                  <div className="div-content" dangerouslySetInnerHTML={{ __html: page.html }} />
                </Container>

                <Container>
                  <div className="div-content">
                    <h3>{page.frontmatter.leftcontentheaderbottom}</h3>
                    <p>{page.frontmatter.leftcontentbottom}</p>
                  </div>
                </Container>
              </>
             }

            {page.frontmatter.slug === "flyttstadning" && 
              <>
                <Container>
                  <Image className="template-content-img" fluid={page.frontmatter.image2.childImageSharp.fluid} alt={page.frontmatter.titleh1}/>
                  <div className="div-content" dangerouslySetInnerHTML={{ __html: page.html }} />
                </Container>    
              </>
            }

            {page.frontmatter.slug === "packtips" && 
              <>
                <Container>
                  <Image className="template-content-img" fluid={page.frontmatter.image2.childImageSharp.fluid} alt={page.frontmatter.titleh1}/>
                  <div className="div-content" dangerouslySetInnerHTML={{ __html: page.html }} />
                </Container>      
            </> }

            {page.frontmatter.slug === "kontakt" && 
              <>
              <CardsboxKontakt />
              <Container className="kontakt-map">

              </Container>
              <Kontaktform />      
            </> }
            
            {page.frontmatter.slug === "success" && 
              <>
              <Success />      
            </> } 
         </div>
        </main>
      </TemplateStyles>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html 
      frontmatter { 
        titleh1
        titleh2
        subtitle
        date
        slug 
        seotitle
        seodescription
        leftcontentheader
        rightcontentheader
        leftcontent
        rightcontent
        leftcontentheaderbottom
        leftcontentbottom
        rightcontentheaderbottom
        rightcontentbottom
        class
        herologo {
          childImageSharp {
            fluid(maxWidth: 1600,  quality: 90) { 
              ...GatsbyImageSharpFluid
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1600,  quality: 90) { 
              ...GatsbyImageSharpFluid
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(maxWidth: 1600,  quality: 90) { 
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
