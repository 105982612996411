import styled from "styled-components"

export const KontaktFormHolder = styled.div`
background:#f7f7f7;
width:100%;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;

.mina-uppgifter {
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center;
    margin-bottom:70px;
    width: 80%;
    margin: 0 auto;
    padding-top: 70px;

    @media screen and (min-width: 768px) {
        flex-direction:row;
        justify-content:space-around;
    }

    .row-del {
        width:90%;
        @media screen and (min-width: 768px) {
        width:50%;

        }
        input {
            height:40px;
            border:1px solid #f2f2f2;
            border-radius:0px;
            width:90%;
            margin-bottom:15px;
            padding-left:15px;
        }
        label {
            font-family: 'Nunito', sans-serif;

        }
        textarea {
            width:100%;
            height:152px;
            border:none;
            padding:15px;

            @media screen and (min-width: 768px) {
            width: 100%;
            height: 205px;
            }
        }
    }
}

.form-end {
    padding:35px;
    text-align:center;

    
    .gdpr {
        width: 70%;
        margin: 0 auto;
        padding-top: 35px;
        font-family: 'Nunito', sans-serif;
        font-size:.8rem;
        padding-bottom:35px;

        input {
            margin-right:10px;
            
        }
    }
    .button {
        background:${props => props.theme.colors.prime};
        color:white;
        transition:.3s;
        padding:7px 15px;
        border: 1px solid ${props => props.theme.colors.prime};
        font-family:'Nunito', sans-serif;
        text-transform:uppercase;
        text-align:center;
        border-radius:7px;
        max-width:300px;
        width:50%;

        :hover {
            background:transparent;
            border:1px solid ${props => props.theme.colors.prime};;
            color:${props => props.theme.colors.prime};;
            cursor:pointer;
        }
    }
}


`