import React from 'react'

// Styles
import { KontaktFormHolder } from "./KontaktForm.styles"

const Kontaktform = () => (
    <KontaktFormHolder>
        <form 

        name="Contact Form"
        method="POST"
        action="/success"
        data-netlify="true"
        data-netlify-honeypot="bot-field"        
      >
         <input type="hidden" name="form-name" value="Contact Form" />
    <input type="hidden" name="bot-field" />

          <div className="mina-uppgifter">
            <div className="row-del">
                <input placeholder="Namn" type="text" name="Förnamn" />
                <input placeholder="Efternamn" type="text" name="Efternamn" id="efternamn_mag" />
                <input placeholder="Epost" type="text" name="Epost" id="email_mag" />
                <input
                placeholder="Telefon"
                type="number"
                name="Telefon"
                id="number_mag"
                />
                
            </div>
            
            <div className="row-del">
                
                    <textarea
                        placeholder="Meddelande"
                        name="Medelandde"
                        id="textarea"
                    ></textarea>        
            </div>
            </div> {/* end mina-uppgifter */}

            <div className="form-end">
                 
                <div className="gdpr">
                <input type="checkbox" name="checkbox_kon" required 
                value="
                Genom att jag har skickat detta meddelande samtycker jag till att 
                ni tar del av de personuppgifter jag har valt att delge.
                "/>
                
                <label>
                    Flyttexpressen lagrar bokningshistorik och personuppgifter som 
                    kunden lämnar och behandlar dessa uppgifter i enlighet med gällande 
                    GDPR (General Data Protection Regulation). Genom att skicka offertförfågan 
                    godkänner du användaravtalet.
                </label>
                </div>
                <input className="button " type="submit" value="Skicka" />
            </div>
      </form>
    </KontaktFormHolder>

)

export default Kontaktform
