import styled from "styled-components"

export const CardboxKontaktHolder = styled.div`

.container {
    .card-group {
        margin-top: -100px;
        position: relative;
        z-index: 2;

        .card {
            border: none;
            margin: 5px;
            border-radius: 2px;

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            .card-img-holder {
                height: 150px;
                display: flex;
                justify-content: center;
                align-items: center;
                display: none;
                .card-img-top {
                    width: 100px;
                    opacity: .9;
                }
            }
            
            .card-body {
                box-shadow: 2px 2px 5px #555;
                padding: .75em;

                svg {
                    font-size: 2rem;
                    margin:20px auto;
                    color: ${props => props.theme.colors.prime};
                }
                .card-title {
                    font-size: 1.1rem;
                    color: #555;
                }
                p {
                    font-size: .8rem;
                    margin-bottom: 2px;
                    color: #555;

                    a {
                        font-family: 'Ubuntu',sans-serif !important;
                        font-weight: 300;
                        font-size: .9rem;
                        color: ${props => props.theme.colors.prime};

                        &:hover {
                            text-decoration: none;
                            color: #555;
                        }
                    }
                }
            }
            .card-footer {
                padding: 0;
                a {
                    background: ${props => props.theme.colors.prime};
                    color: white;
                    width: 100%;
                    height: 100%;
                    display: block;
                    text-align: center;
                    font-family: 'Ubuntu', sans-serif !important;
                    transition:.3s;

                    &:hover {
                        background: #3f444f;
                        color: white;
                        text-decoration:none;
                    }
                }
            }
        }
    }
}
`
