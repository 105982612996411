import styled from "styled-components"


export const SuccessHolder = styled.div`
background:green;
color:white;
width:50%;
height:50%;
margin:auto auto;

`