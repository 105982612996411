import styled from "styled-components"

export const TemplateStyles = styled.div`

.bohagsflytt {
    h1 {
        
    }

    .page-hero-logo-img {
        margin: 0 auto;
        position: absolute !important;
        top: 16vh;
        z-index: 9;
        width: 50%;
        left: 25%;

        @media screen and (min-width: 767px)  {
            width: 10%;
            left: 45%;
        }
    }

    .hero-subtitle {
        color: ${props => props.theme.colors.prime};
        position: absolute;
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        top: 45vh;
        width: 100%;
        max-width: 1600px;
        text-align: center;
        z-index:2;

        @media screen and (min-width: 767px)  {
            top: 35vh;
        }
    }

    .subpage-text {
        padding-top: 0;
        .subpage-twocols-holder {
            margin-top: 0;

            &.swish-svar {
                .right-col {
                    div {
                        padding-bottom: 35px;


                    }
                }
            }

            .container {
                .row {
                    .col {
                       > h3 {
                            display: none;
                        }

                        div {
                            background: #F4F4F4;
                            border-radius: 7px;
                            position: relative;

                            h3 {
                                margin: 0 auto;
                                font-size: 1rem;
                                width: 60%;
                                @media screen and (min-width: 1600px)  {
                                    font-size: 1.5rem;
                                    width: 90%;
                                }
                            }

                            p {
                                text-align: left;
                                font-size: .8rem;
                                color: #777;
                                padding: 35px;

                                @media screen and (min-width: 1600px)  {
                                font-size: 1.3rem;
                                }
                            }
                        }
                    }
                }
            }

            .rubrik-main {
                display: none;
            }
        }

        .container {
            .div-content {
                background: #F4F4F4;
                border-radius: 7px;
                position: relative;
                margin-top: 70px;

                h3 {
                    margin: 0 auto;
                    padding-top: 15px;
                    font-size: 1rem;
                    @media screen and (min-width: 1600px)  {
                        font-size: 1.5rem;
                        }
                }

                p {
                    text-align: left;
                    font-size: .8rem;
                    color: #777;
                    padding: 35px;
                    @media screen and (min-width: 1600px)  {
                        font-size: 1.3rem;
                        }
                }
            }
        }

        .rut-boxar {
            margin-top: 70px;
        }

        .kontakt-map {
            margin: 35px 0;
        }
    }

    .paket-sub {
        display: flex;
        justify-content: space-around;
        margin: 70px auto;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: 767px)  {
            flex-direction: row;

        }

        &.trans-paket {
            div {
                .paket-left {
                    .fran {
                        display: none;
                    }
                    .inc {
                        display: none;
                    }
                }

                .paket-right {
                    .fran {
                        display: none;
                    }
                    .inc {
                        display: none;
                    }
                }
            }
        }

        &.paket-piano {
            flex-wrap: wrap;

            div {
                width: 90%;
                background: transparent;

                @media screen and (min-width: 767px)  {
                    width: 45%;
                }

                .paket-left {
                    margin: 0;
                    p {
                        color: black;
                    }
                    h4{
                        color: black;
                    }
                    a {
                        background: ${props => props.theme.colors.prime};
                        color: #fff;
                        transition: .3s;
                        border: 1px solid ${props => props.theme.colors.prime};
    
                        &:hover {
                            background: transparent;
                            color: ${props => props.theme.colors.prime};
                        }
                    }
                }

                .paket-right {
                    width: 100%;
                    border-radius: 0;
                    border:none;
                    border-top: 1px solid ${props => props.theme.colors.prime};
                    p {
                        color: black;
                    }
                    h3 {
                        color: black;
                    }
                    a {
                        background: ${props => props.theme.colors.prime};
                        color: #fff;
                        transition: .3s;
                        border: 1px solid ${props => props.theme.colors.prime};
    
                        &:hover {
                            background: transparent;
                            color: ${props => props.theme.colors.prime};;
                        }
                    }
                }
            }
        }

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: auto;
            width: 90%;
            border-radius: 7px;
            padding:10px 0;
            background: ${props => props.theme.colors.prime};

            @media screen and (min-width: 767px)  {
                width: 30%;
            }

            .paket-left {
                margin-bottom:35px;
                p {
                    color: white;
                }
                h4 {
                    color: white;
                }
                a {
                    background: white;
                    color: #555;
                    transition: .3s;

                    &:hover {
                        background: #555;;
                        color: white;
                    }
                }
                .inc {
                    margin-bottom: 5px;
                }
            }

            .paket-right {
                width: 70%;
                p {
                    color: white;
                }

                h3 {
                    color: white;
                    font-size: 1rem;
                }
                p {
                    font-size: .8rem;
                }
            }
        }
    }
}

`