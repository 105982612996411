import React from 'react';

import Container from 'react-bootstrap/Container'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup'

import slideett from 'images/gbg.png'
import slidetva from 'images/vaxjo.png'
import slidetre from 'images/boras_stad.png'
import slidefyra from 'images/jpk.png'
import { FiPhoneCall, FiAtSign, FiClock  } from "react-icons/fi"


// Styles
import { CardboxKontaktHolder } from "./CardsboxKontakt.styles"

const Cardsbox = () => {

    return (
        <CardboxKontaktHolder>
            <Container>
                <CardGroup>
                    <Card>
                        <div className="card-img-holder">
                        </div>
                        <Card.Body>
                            <FiPhoneCall />
                        <Card.Title>Telefonnummer</Card.Title>
                        <Card.Text>
                            <p><a href="tel:0737360029">0737 - 360 029</a></p>
                        </Card.Text>
                        </Card.Body>
                    </Card>
                    <Card>
                        <div className="card-img-holder">
                        </div>
                        <Card.Body>
                            <FiAtSign />
                        <Card.Title>E-post</Card.Title>
                        <Card.Text>
                            <p>
                                <a href="mailto:info@flyttexpressen.com">info@flyttexpressen.com</a><br/>
                            (Svar inom 24 timmar)</p>
                        </Card.Text>
                        </Card.Body>  
                    </Card>
                    <Card>
                        <div className="card-img-holder">
                        </div>
                        <Card.Body>
                            <FiClock />
                        <Card.Title>Öppettider</Card.Title>
                        <Card.Text>
                           <p><strong>Måndag-Fredag:</strong> 07.30-16.30<br/>
                                <strog>Lördag:</strog> Stängt<br/>
                                <strong>Söndag:</strong> Stängt</p>
                        </Card.Text>
                        </Card.Body>
                    </Card>
                </CardGroup>
            </Container>
        </CardboxKontaktHolder>
    )
}

export default Cardsbox 